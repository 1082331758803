import { useIntl, FormattedMessage } from 'react-intl';
import messages from '../../../utils/messages';
import './styles.css';
import { PropTypes } from 'prop-types';

const WhatsNew = ({ lang }) => {
  const intl = useIntl();
  const t = intl.formatMessage;

  return (
    <div className="whatsNew">
      <div className="topTip"><span>{t(messages.whatsNewTop)}</span></div>
      <ul>
        <li><span>
          <FormattedMessage
            id={messages.whatsNewLI1.id}
            defaultMessage={messages.whatsNewLI1.defaultMessage}
            values={{b: (chunks) => <strong className="strong">{chunks}</strong>}}
          />
        </span></li>
        <li><span>
          <FormattedMessage
            id={messages.whatsNewLI2.id}
            defaultMessage={messages.whatsNewLI2.defaultMessage}
            values={{
              b: (chunks) => <strong className="strong">{chunks}</strong>,
              i: (chunks) => <em>{chunks}</em>
            }}
          />
        </span></li>
      </ul>
      <div className="topTip"><a href="https://www.boatsgroup.com/product-updates/" target="_blank" rel="noreferrer noopener">{t(messages.whatsNewP1)}</a></div>
      <div className="topTip paddingTop"><span>{t(messages.whatsNewP2)}</span></div>
      <div className="paddingTop paddingLeft"><span>
        <FormattedMessage
          id={messages.whatsNewP3.id}
          defaultMessage={messages.whatsNewP3.defaultMessage}
          values={{b: (chunks) => <strong className="strong">{chunks}</strong>}}
        />
      </span></div>

    </div>
  );
};

WhatsNew.propTypes = {
  lang: PropTypes.string
};

export default WhatsNew;
