import { defineMessages } from 'react-intl';

const messages = defineMessages({
  accountManager: {
    id: 'accountManager',
    defaultMessage: 'Account Manager'
  },
  addlisting: {
    id: 'addlisting',
    defaultMessage: 'Add a Listing'
  },
  addedListings: {
    id: 'addedListings',
    defaultMessage: 'Listings Added'
  },
  advertisement: {
    id: 'advertisement',
    defaultMessage: 'Advertisement'
  },
  boatsdotcom: {
    id: 'boatsdotcom',
    defaultMessage: 'boats.com'
  },
  comingSoon: {
    id: 'comingSoon',
    defaultMessage: 'Coming Soon'
  },
  copyright: {
    id: 'copyright',
    defaultMessage: 'Copyright © {year} Boats Group.'
  },
  copyright2: {
    id: 'copyright2',
    defaultMessage: 'All Rights Reserved. BoatWizard™ since 1997.'
  },
  crmStats: {
    id: 'crmStats',
    defaultMessage: 'Contact Manager Stats'
  },
  directEmails: {
    id: 'directEmails',
    defaultMessage: 'Direct Emails'
  },
  email: {
    id: 'email',
    defaultMessage: 'Email'
  },
  error: {
    id: 'error',
    defaultMessage: 'Something went wrong'
  },
  fourAndOlderLeads: {
    id: 'fourAndOlderLeads',
    defaultMessage: '4-180 days old'
  },
  helpfulTips: {
    id: 'helpfulTips',
    defaultMessage: 'Helpful Tips'
  },
  whatsNew: {
    id: 'whatsNew',
    defaultMessage: 'What\'s New'
  },
  lastNDays: {
    id: 'lastNDays',
    defaultMessage: 'Last {n} Days'
  },
  helpfulTipsTop: {
    id: 'helpfulTipsTop',
    defaultMessage: 'Listing Score reviews your listings in real-time and gives each one a score out of 100%. Statistics have shown that listings with more details such as these receive more leads.'
  },
  helpfulTipLI2: {
    id: 'helpfulTipLI2',
    defaultMessage: 'Including information such as a boat class, engine model, engine hours, and hull identification number can help improve your Listing Score.'
  },
  helpfulTipLI3: {
    id: 'helpfulTipLI3',
    defaultMessage: 'Adding an additional boat class can help your listings get seen by more prospective buyers.'
  },
  helpfulTipLI1: {
    id: 'helpfulTipLI1',
    defaultMessage: 'To achieve a higher listing score, upload as many quality photos and videos as you have. Make sure you display your best one first.'
  },
  whatsNewTop: {
    id: 'whatsNewTop',
    defaultMessage: 'New Updates to the ProSeller Platform!'
  },
  whatsNewLI1: {
    id: 'whatsNewLI1',
    defaultMessage: 'You can now expand your market view by including private seller listings in your searches. Private seller listings are hidden by default, but you can toggle them on or off by selecting <b>“Hide Private Seller”</b>.'
  },
  whatsNewLI2: {
    id: 'whatsNewLI2',
    defaultMessage: 'We’ve also introduced a <b>‘Sold Listings’ search filter</b>, making it easier to toggle between active and sold boats for in-depth market analysis. <i>Please note: You must be a Soldboats user to access sold listings.</i>'
  },
  whatsNewLI3: {
    id: 'whatsNewLI3',
    defaultMessage: 'A new ‘Sold Listings’ search filter will let you easily toggle between active and sold boats for more in-depth market analysis.'
  },
  whatsNewLI4: {
    id: 'whatsNewLI4',
    defaultMessage: 'Soon, you’ll be able to include private seller listings in your searches. Private seller listings will be hidden by default, but you can toggle them on or off.'
  },
  whatsNewLI5: {
    id: 'whatsNewLI5',
    defaultMessage: 'Enjoy light design updates, including refreshed buttons, icons, and colors, for a modern and more user-friendly interface.'
  },
  whatsNewP1: {
    id: 'whatsNewP1',
    defaultMessage: 'See more product updates!'
  },
  whatsNewP2: {
    id: 'whatsNewP2',
    defaultMessage: 'Coming soon:'
  },
  whatsNewP3: {
    id: 'whatsNewP3',
    defaultMessage: 'The <b>Market Evaluation Tool</b>, exclusively for Advantage Select customers, will provide a complete market overview with the click of a button—all within the ProSeller Platform.'
  },
  leadsMonth: {
    id: 'leadsMonth',
    defaultMessage: 'Leads for this month: {leads}'
  },
  listingStats: {
    id: 'listingStats',
    defaultMessage: 'Boat Stats'
  },
  myTeam: {
    id: 'myTeam',
    defaultMessage: 'My Team'
  },
  offSoldListings: {
    id: 'offSoldListings',
    defaultMessage: 'Sold Listings'
  },
  onListings: {
    id: 'onListings',
    defaultMessage: '"On" Listings'
  },
  others: {
    id: 'others',
    defaultMessage: 'Others'
  },
  phone: {
    id: 'phone',
    defaultMessage: 'Phone'
  },
  pleaseWait: {
    id: 'pleaseWait',
    defaultMessage: 'Please wait...'
  },
  removedListings: {
    id: 'removedListings',
    defaultMessage: 'Listings Removed'
  },
  salesRep: {
    id: 'salesRep',
    defaultMessage: 'Regional Sales Consultant'
  },
  seeMyLeads: {
    id: 'seeMyLeads',
    defaultMessage: 'See My Leads'
  },
  statsConnections: {
    id: 'statsConnections',
    defaultMessage: 'Connections'
  },
  statsImpressions: {
    id: 'statsImpressions',
    defaultMessage: 'Impressions'
  },
  statsLeads: {
    id: 'statsLeads',
    defaultMessage: 'Leads'
  },
  statsViews: {
    id: 'statsViews',
    defaultMessage: 'Views'
  },
  support: {
    id: 'support',
    defaultMessage: 'Customer Support'
  },
  todayLeads: {
    id: 'todayLeads',
    defaultMessage: 'Received today'
  },
  total: {
    id: 'total',
    defaultMessage: 'Total'
  },
  twoThreeDayLeads: {
    id: 'twoThreeDayLeads',
    defaultMessage: '2-3 days old'
  },
  unreadLeads: {
    id: 'unreadLeads',
    defaultMessage: 'Unread leads: {leads}'
  },
  viewMyGallery: {
    id: 'viewMyGallery',
    defaultMessage: 'View My Gallery'
  },
  welcome: {
    id: 'welcome',
    defaultMessage: 'Welcome, {name}!'
  },
  yw: {
    id: 'yw',
    defaultMessage: 'YachtWorld.com'
  },
  financing: {
    id: 'financing',
    defaultMessage: 'Financing'
  },
  financingTitle: {
    id: 'financingTitle',
    defaultMessage: 'Earn a referral fee on all closed loans!'
  },
  financingSubtitle: {
    id: 'financingSubtitle',
    defaultMessage: 'Give your customers fast, easy financing with the best rates.'
  },
  financingButtonCopiedText: {
    id: 'financingButtonCopiedText',
    defaultMessage: 'Copied!'
  },
  financingButtonText: {
    id: 'financingButtonText',
    defaultMessage: 'Copy Link'
  },
  financingButtonSubject: {
    id: 'financingButtonSubject',
    defaultMessage: 'Share your financing application link with clients.'
  },
  financingCallText1: {
    id: 'financingCallText1',
    defaultMessage: 'Call Trident Funding at '
  },
  financingCallText2: {
    id: 'financingCallText2',
    defaultMessage: ' to learn more.'
  }
});

export default messages;
